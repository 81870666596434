<template>
  <div class="main gray-bg">
    <van-nav-bar class="top-bar" title="申请记录" left-arrow @click-left="onClickLeft"/>
    <van-list class="card-pane" v-model:loading="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="getList">
      <div class="card-cell" v-for="item in list" :key="item.id" @click="$router.push('/personal/activity-rebate-detail/'+`${item.id}`)">
        <div class="card-block">
          <div class="card-block-body">
            <div class="card-block-item">
              <div class="label">申请活动：</div>
              <div class="value van-ellipsis">{{ item.game_activity_title}}</div>
            </div>
            <div class="card-block-item">
              <div class="label">申请日期：</div>
              <div class="value van-ellipsis">{{date(item.create_time, 'Y-m-d H:i:s')}}</div>
            </div>
            <div class="card-block-item">
              <div class="label">申请状态：</div>
              <div class="value van-ellipsis">
                <span :style="{'color':typeFormat(item.status,statusConfig.status_style)}">{{typeFormat(item.status,statusConfig.status_text)}}</span>
              </div>
              <van-tag v-if="item.status == 3" style="cursor: pointer" color="#4983FE" @click.stop="asyncCopy(item.remark)" plain round>复制</van-tag>
            </div>
          </div>
          <van-button v-if="item.status == 4" round type="primary" size="small" @click.stop="$router.push('/personal/activity-rebate/'+`${item.active_id}/${item.game_name}/${item.id}`)">重新申请</van-button>
        </div>
        <div class="card-remark" v-if="item.status == 3 || item.status == 4">{{item.remark}}</div>
      </div>
    </van-list>
  </div>
</template>

<script>
import {Button, List, NavBar, PullRefresh, Tag} from "vant";
export default {
  name: "ActivityRebateList",
  components:{
    [NavBar.name]:NavBar,
    [Tag.name]:Tag,
    [List.name]:List,
    [PullRefresh.name]:PullRefresh,
    [Button.name]: Button,
  }
}
</script>
<script setup>
//初始化
import {reactive, ref} from "vue";
import {rebateList} from "@/api/user_active_rebate";
import {date,onClickLeft,typeFormat,asyncCopy} from "@/utils/function";

const list = ref([]);
const query = reactive({
  last:0,
  limit:10
})
const loading = ref(false);
const finished = ref(false);

const statusConfig = {
  status_text:{
    1:"申请中",
    2:"已审核",
    3:"已发放",
    4:"已驳回"
  },
  status_style:{
    1:"#4983FE",
    2:"#F27E30",
    3:"#13A404",
    4:"#FF0000"
  }
}
const getList = ()=>{
  loading.value = true;
  let oldList = list.value;
  rebateList(query).then(({data})=>{
    list.value = [...oldList, ...data.list]
    query.last = data.last
    if(data.list.length < query.limit || data.last == 0){
      finished.value = true;
    }
  }).catch(()=>{
    loading.value = false;
    finished.value = true;
  })
}

</script>
<style scoped>
.card-pane{
  padding:12px;
}
.card-cell{
  background: #FFFFFF;
  margin-bottom:12px;
  padding:12px;
  border-radius: 5px;
}
.card-block{
  display:flex;
  align-items: center;
}
.card-block-body{
  flex:1;
}
.card-block-item{
  display: flex;
  align-items: center;
  height: 26px;
  line-height: 26px;
  overflow:hidden;
  text-align: left;
  font-size: 14px;
  color: #1D2129;
}
.card-block-item .label{
  width:80px;
  font-weight:bold;
}
.card-block-item .value{
  flex: 1;
  font-size: 12px;
}
.card-remark{
  background: #F7F8FA;
  border-radius: 3px;
  padding:6px 12px;
  font-size: 13px;
  font-weight: 400;
  color: #747C89;
  line-height:24px;
  text-align: left;
}
</style>
